import api from ".";
import newApi from "./newApi";
import { instance as client } from "./client_document";
import { instance2 as createFile } from "./client_file";

const regions = ["COSTA", "NORTE", "CENTRO", "SUR", "CENTRO OCCIDENTE"];
const dataSource = [
  {
    key: "1",
    nombre: "Indicador Económico",
    tipo: "Financiero",
    formula: "PIB = C + I + G + (X - M)",
  },
  {
    key: "2",
    nombre: "Indicador Demográfico",
    tipo: "Poblacional",
    formula:
      "Tasa de Crecimiento = (Población Final - Población Inicial) / Población Inicial",
  },
  {
    key: "3",
    nombre: "Indicador Ambiental",
    tipo: "Ecológico",
    formula: "Huella de Carbono = Suma de emisiones de CO2",
  },
  {
    key: "4",
    nombre: "Indicador de Salud",
    tipo: "Sanitario",
    formula: "Tasa de Mortalidad = Muertes / Población Total",
  },
  {
    key: "5",
    nombre: "Indicador Tecnológico",
    tipo: "Innovación",
    formula: "Índice de Innovación = Inversiones en I+D / Ingresos Totales",
  },
  {
    key: "6",
    nombre: "Indicador Educativo",
    tipo: "Académico",
    formula:
      "Tasa de Alfabetización = Población Alfabetizada / Población Total",
  },
];

const returnCodeDane = (value) => value.split("*")[1] 
// #region Advertisements
const getIndicators = (pageNumber = 1, page_size = 10, search = "") => {
  // const url = `/web/v1/Indicator/advertising?page[size]=${page_size}&page[number]=${pageNumber}${search}`;
  // eslint-disable-next-line no-console
  console.log("get indicators", pageNumber, page_size, search);
  return dataSource;
};

const getDataIndicator = ({
  municipalities = "",
  pageNumber = 1,
  page_size = 10,
  search = "total_population_men",
  startDate = "2000-01-01",
  endDate = "2035-12-31",
  // variable = "Demográficos",
}) => {
  let municipalitiesParams = "";
  if (Array.isArray(municipalities) && municipalities.length > 0) {
    municipalitiesParams = municipalities.map((city) => {
      let auxNameLocation = "&cities";
      let auxCity = returnCodeDane(city)
      if (regions.includes(city)){
        auxNameLocation = "&sub_regions";
        auxCity = city
      } 
      return `${auxNameLocation}=${auxCity}`;
    });
    municipalitiesParams = municipalitiesParams.join("");
  } else {
    let tempVar = "&cities";
    let auxCity = returnCodeDane(municipalities)
    if (regions.includes(municipalities)) {
      tempVar = "&sub_regions";
      auxCity = municipalities
    }
    municipalitiesParams = `${tempVar}=${auxCity}`;
  }
  const url = `/indicators/tracer_indicators?limit=${page_size}&offset=${pageNumber}&name=${search}&start_date=${startDate}&end_date=${endDate}${municipalitiesParams}`;
  const auxUrl = encodeURI(url)
  return newApi.get(auxUrl);
};

const getGroupIndicators = () => {
  const url = "/files/indicator-groups";
  return api.get(url);
};

const getIndicatorsByGroup = (idGroup) => {
  const url = `/files/indicator-types/${idGroup}`;
  return api.get(url);
};

const getMunicipalitiesByIndicator = (
  idIndicador,
  page = 1,
  page_size = 50
) => {
  const url = `/indicators/tracer_indicators/indicator_municipalities?type=${idIndicador}&limit=${page_size}&offset=${page}`;
  const auxUrl = encodeURI(url);
  return newApi.get(auxUrl);
};
const getRegionByIndicator = (idIndicador) => {
  const url = `/files/indicator-municipalities/${idIndicador}/${true}`;
  return api.get(url);
};
const getIndicatorsTaxonomies = () => {
  const url = "/files/all-indicator-groups/";
  return client.get(url);
};

const createPostulateIndicator = (data) => {
  const url = "/indicators/proposal";
  return newApi.post(url, data);
};

const createManyPostulationIndicator = (data) => {
  const url = "/indicators/proposal/many-create";
  return newApi.post(url, data);
};

const updatedIndicatorSuggestions = (data) => {
  const url = "/indicators/proposal/many-update";
  return newApi.post(url, data);
};

const deletePostulateIndicator = (data) => {
  const url = "/indicators/proposal/delete";
  return newApi.post(url, data);
};

const getPostulatesIndicator = (id, page_size, page) => {
  const url = `/indicators/proposal?indicatorGroup=${id}&limit=${page_size}&offset=${page}`;
  return newApi.get(url);
};

const updateProposal = (id, data) => {
  const url = `/indicators/proposal/${id}`;
  return newApi.put(url, data);
};

const getIndicatorGroups = () => {
  const url = "/indicators/indicator/list_deep";
  return newApi.get(url);
};

const getIndicatorById = (id) => {
  const url = `/indicators/indicator/get_indicators/${id}`;
  return newApi.get(url);
};

const getListTaxonomies = (list_all = true) => {
  const url = `/indicators/taxonomy/list?list_all=${list_all}`;
  return newApi.get(url);
};

const downloadTaxonomy = (id) => {
  const url = `/indicators/taxonomy/download?id=${id}`;
  return newApi.get(url);
};

const updateTaxonomies = (ids, data) => {
  let auxIds = `?ids=${ids}`;
  if (Array.isArray(ids) && ids.length > 0) {
    auxIds = ids.map((item, index) => {
      if (index === 0) {
        return `?ids=${item}`;
      }
      return `&ids=${item}`;
    });
    auxIds = auxIds.join("");
  }
  const url = `/indicators/taxonomy${auxIds}`;
  return createFile.put(url, data);
};

const createTaxonomy = (data) => {
  const url = "/indicators/taxonomy";
  return createFile.post(url, data);
};

const deleteTaxonomy = () => {
  const url = "/indicators/taxonomy/delete";
  return newApi.delete(url);
};

export default {
  getDataIndicator,
  getIndicators,
  getGroupIndicators,
  getIndicatorsByGroup,
  getMunicipalitiesByIndicator,
  getIndicatorsTaxonomies,
  createPostulateIndicator,
  createManyPostulationIndicator,
  deletePostulateIndicator,
  getRegionByIndicator,
  getPostulatesIndicator,
  getIndicatorGroups,
  getIndicatorById,
  getListTaxonomies,
  updateProposal,
  updateTaxonomies,
  downloadTaxonomy,
  createTaxonomy,
  deleteTaxonomy,
  updatedIndicatorSuggestions,
};
