/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Menu } from "antd";
import {
  CheckSquareOutlined,
  DiffOutlined,
  FileTextOutlined,
  FundViewOutlined,
  HistoryOutlined,
  HomeOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PartitionOutlined,
  SearchOutlined,
  UserOutlined,
  ReadOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import Tour from "reactour";
import Flag from "../../atoms/Img/AppImg";
import LogoHeader from "../../../assets/image/LogoAUNE_w.png";
import DropdownAvatar from "../../molecules/DropdownAvatar/DropdownAvatar";
import ItemMenuAvatar from "../../molecules/ItemMenuAvatar.js/ItemMenuAvatar";
import ROUTES from "../../../constant/router";
import { useAuth } from "../../../contexts/AuthContext";
import TABS from "../../../constant/tabsRole";
import token from "../../../api/token";
import useApi from "../../../api/useApi";
import auth from "../../../api/auth";

const Header = ({ isLanding }) => {
  const getPermissions = useApi(auth.getPermissions);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed((prev) => !prev);
  const [items, setItems] = useState();
  const [current, setCurrent] = useState(ROUTES.GENERAL.HOME);
  const {
    setCurrentUser,
    logout,
    currentUser,
    permissions,
    setPermissions,
    setMessage,
  } = useAuth();
  const navigate = useNavigate();

  const [isTourOpen, setIsTourOpen] = useState(false);
  const steps = [
    {
      selector: '[data-tour=""]',
      content: "Esta es la sección de Inicio.",
    },
    {
      selector: '[data-tour="documents"]',
      content: "Esta es la sección de Gestión de archivos.",
    },
    {
      selector: '[data-tour="statement"]',
      content: "Esta es la sección de Informes.",
    },
    {
      selector: '[data-tour="statement-indicators"]',
      content: "Esta es la sección de Informe de indicadores trazadores.",
    },
    {
      selector: '[data-tour="report"]',
      content: "Esta es la sección de Reportes.",
    },
    {
      selector: '[data-tour="indicators"]',
      content: "Esta es la sección de Gestión de postulaciones.",
    },
    {
      selector: '[data-tour="users/list"]',
      content: "Esta es la sección de Gestión de usuarios.",
    },
    {
      selector: '[data-tour="published-research"]',
      content: "Esta es la sección de Investigaciones publicadas.",
    },
    {
      selector: '[data-tour="research"]',
      content: "Esta es la sección de Investigaciones.",
    },
    {
      selector: '[data-tour="comparative-analysis"]',
      content: "Esta es la sección de Análisis comparativo.",
    },
    {
      selector: '[data-tour="applications"]',
      content: "Esta es la sección de Solicitudes.",
    },
    {
      selector: '[data-tour="history"]',
      content: "Esta es la sección de Historial.",
    },
  ];

  const logoutUser = (e) => {
    if (e.key === "logout") logout();
  };

  const pressTuto = () => {
    setMessage({
      type: "success",
      title: "¡Bienvenido a AUNE!",
      description:
        "Estamos encantados de tenerte aquí. A continuación, te guiaremos en un recorrido por todas las características y funciones que ofrecemos. Esperamos que disfrutes de la experiencia y encuentres todo lo que necesitas.",
    });
    setIsTourOpen(true);
  };

  const presTutoMount = () => {
    setIsTourOpen(false);
    // Configurar la cookie
    Cookies.set("tutorialHome", "started", { expires: 7 });
    Cookies.set("tutorialGA", "started", { expires: 7 });
    Cookies.set("tutorialInfo", "started", { expires: 7 });
    Cookies.set("tutorialInfoIT", "started", { expires: 7 });
    Cookies.set("tutorialReport", "started", { expires: 7 });
    Cookies.set("tutorialGI", "started", { expires: 7 });
    Cookies.set("tutorialGU", "started", { expires: 7 });
    Cookies.set("tutorialIP", "started", { expires: 7 });
    Cookies.set("tutorialIvesti", "started", { expires: 7 });
    Cookies.set("tutorialAC", "started", { expires: 7 });
    Cookies.set("tutorialSolici", "started", { expires: 7 });
    Cookies.set("tutorialHisto", "started", { expires: 7 });
    // La cookie expirará en 7 días
    setCollapsed(false);
  };

  const { pathname } = window.location;

  const renderIcon = (keyRoute) => {
    switch (keyRoute) {
      case "/":
        return <HomeOutlined />;
      case "/research":
        return <SearchOutlined />;
      case "/published_research":
        return <SearchOutlined />;
      case "/documents":
        return <FileTextOutlined />;
      case "/report":
        return <DiffOutlined />;
      case "/statement":
        return <FundViewOutlined />;
      case "/statement_indicators":
        return <LineChartOutlined />;
      case "/management_indicators":
        return <FontSizeOutlined />;
      case "/users/list":
        return <UserOutlined />;
      case "/indicators":
        return <PartitionOutlined />;
      case "/applications":
        return <CheckSquareOutlined />;
      case "/history":
        return <HistoryOutlined />;
      case "/comparative_analysis":
        return <FundViewOutlined />;
      default:
        return <HomeOutlined />;
    }
  };
  const isDisabled = (keyRoute) => {
    if (currentUser.role === "Funcionario Público") {
      switch (keyRoute) {
        case "/dashboard":
          return false;
        case "/statement_indicators":
          return false;
        case "/research":
          return false;
        default:
          return true;
      }
    }
    switch (keyRoute) {
      case "/report":
        return true;
      case "/applications":
        return true;
      case "/history":
        return true;
      case "/statement":
        return true;
      case "/research":
        return true;
      case "/published_research":
        return true;
      default:
        return false;
    }
  };

  const handleClickTab = ({ key }) => {
    setCurrent(key);

    if (key === "logout") {
      setCurrentUser();
      setCollapsed(false);
      logout();
    } else if (key === "tutorial") {
      pressTuto();
    } else {
      navigate(key);
      setCollapsed(false);
    }
  };

  useEffect(() => {
    if (
      getPermissions.data &&
      getPermissions.data.data &&
      getPermissions.data.data.permissions
    ) {
      let auxPermissions = getPermissions.data.data.permissions.permissions;
      auxPermissions = auxPermissions.map((item) => item.action);
      setPermissions(auxPermissions);
    }
  }, [getPermissions.data]);

  useEffect(() => {
    if (currentUser && currentUser.role) {
      if (permissions.length === 0) {
        getPermissions.request();
      }
      /*  getPermissions.setData({
        permissions: [
          "document_management.read",
          "document_management.create",
          "document_management.edit",
          "document_management.delete",
          "document_management.download",
          "document_management.share",
          "indicator.read",
          "indicator.readreport",
          "indicator.create",
          "indicator.calculate",
          "report.read",
          "report.create",
          "statement.read",
          "statement.create",
          "userown.read",
          "research.read",
          "research.create",
          "research.download",
          "research.edit",
          "research.pause",
          "researchpublished.read",
          "researchpublished.download",
          "user.read",
          // "userown",
        ],
      }); */
      const auxItems = TABS[currentUser.role];
      if (auxItems) {
        setItems(auxItems);
        const auxPath = pathname.split("/")[1];
        const auxItem = Object.entries(auxItems).filter(([_, value]) =>
          value.key.includes(auxPath)
        );
        if (Array.isArray(auxItem)) {
          if (auxItem[0]) {
            setCurrent(auxItems[auxItem[0][0]].key);
          }
        }
      }
    }
  }, [currentUser]);

  const renderItemsHeader = () => {
    if (currentUser && !isLanding) {
      return (
        <div className="hidden md:block md:absolute right-0 no-margin-drop mt-1">
          <DropdownAvatar
            className="inline-block px-4 py-2 cursor-pointer leading-none hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
            handleMenuClick={logoutUser}
            infoUser={currentUser}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Row className="absolute top-0 w-full bg-backGroundBanner max-h-[84px] flex-nowrap">
        {currentUser && !isLanding ? (
          <button
            type="button"
            className="text-white text-2xl ml-6 mr-4 md:right-12 top-6 text-white_custom"
            onClick={toggleCollapsed}
          >
            {!collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </button>
        ) : null}
        <Col className="self-center justify-center xl:justify-normal">
          <a href="/dashboard" rel="noreferrer">
            <Flag
              src={LogoHeader}
              alt="Aune"
              className=" fill-current mr-2 ml-2 w-44 "
            />
          </a>
        </Col>
        {renderItemsHeader()}
      </Row>
      {currentUser && !isLanding ? (
        <Menu
          className={`${collapsed ? "block" : "hidden"} bg-white_custom z-[999] text-[#747878] h-[calc(100vh-84px)] top-[84px] absolute rounded-e-custom shadow-lg pt-5 min-w-[200px] flex flex-col`}
          onClick={handleClickTab}
          selectedKeys={[current]}
          mode="vertical"
        >
          <Menu.Item
            className="h-20 block md:hidden"
            key={ROUTES.GENERAL.USER_EDIT}
          >
            <ItemMenuAvatar infoUser={currentUser} />
          </Menu.Item>
          {items
            ? items.map((val) => (
              <Menu.Item
                key={val.key}
                icon={renderIcon(val.key)}
                disabled={isDisabled(val.key)}
                className="flex items-center"
                data-tour={val.key.slice(1).replaceAll("_", "-")}
              >
                {val.label}
              </Menu.Item>
            ))
            : null}

          <Menu.Item
            key="tutorial"
            icon={<ReadOutlined />}
            className="flex items-center mt-auto mb-5"
          >
            Tutorial
          </Menu.Item>
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            className="flex items-center mt-auto mb-5"
          >
            Cerrar Sesión
          </Menu.Item>
        </Menu>
      ) : null}
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={presTutoMount} />
    </>
  );
};

export default Header;
