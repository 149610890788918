/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import HomeForm from "../../organisms/HomeForm/HomeForm";
import MapCard from "../../organisms/MapCard/MapCard";
import useApi from "../../../api/useApi";
import MapRequest from "../../../api/MapRequest";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import transformToCheckArray from "../../../helpers/transformToCheckArray";
import transformToMap from "../../../helpers/transformToMap";
import { useAuth } from "../../../contexts/AuthContext";
import HomeLastFiles from "../../organisms/HomeForm/HomeLastFiles";
import HomePreviewIndicatorChart from "../../organisms/HomeForm/HomePreviewIndicatorChart";
import document from "../../../api/document";
import HomeLastAnalysis from "../../organisms/HomeForm/HomeLastAnalysis";
import analysisRequest from "../../../api/analysisRequest";

const HomeTemplate = ({
  handleItemZone,
  handleItemEvent,
  zonesSelected,
  eventsSelected,
}) => {
  const deptoCode = 52;
  const [mapData, setMapData] = useState([]);
  const requestDataMap = useApi(MapRequest.getDataMap);
  const [municipalitiesList, setMunicipalitiesList] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const [listHealthEvents, setListHealthEvents] = useState([]);
  const [listLastFiles, setListLastFiles] = useState([]);
  const [listAnalysis, setListAnalysis] = useState([]);
  const [form] = Form.useForm();
  const { setMessage, currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    request: getMunicipalities,
    data: municipalities,
    setData: setDataMunicipalities,
  } = useApi(MapRequest.getMunicipalities);
  const {
    request: getEventsHealth,
    data: eventsHealth,
    setData: setEventsHealth,
  } = useApi(MapRequest.getEvents);
  const {
    request: getEventsViolence,
    data: eventsViolence,
    setData: setEventsViolence,
  } = useApi(MapRequest.getEvents);

  const getAllDocuments = useApi(document.getAllDocuments);
  const getAnalysis = useApi(analysisRequest.getAllAnalysis);

  const getMyLastDocuments = () => getAllDocuments.request(10, 1, "all-files");

  useEffect(() => {
    getMunicipalities(false, false);

    getEventsHealth("salud");
    getEventsViolence("violencia");
    getMyLastDocuments();
    getAnalysis.request(null, 50, 1);
    // getEvents("seguridad");
  }, []);

  useEffect(() => {
    if (municipalities) {
      const aux = transformToSelectArray(
        municipalities.data,
        "municipalityName",
        "municipalityCode"
      );
      const sortedData = [...aux].sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setMunicipalitiesList(sortedData);
      setDataMunicipalities();
    }

    if (eventsHealth) {
      if (Array.isArray(eventsHealth.data)) {
        const auxViolence = transformToSelectArray(
          eventsHealth.data,
          "name",
          "_id"
        );
        const sortedHealth = [...auxViolence].sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        setListHealthEvents(sortedHealth);

        setEventsHealth();
      }
    }

    if (eventsViolence) {
      if (Array.isArray(eventsViolence.data)) {
        const auxViolence = transformToSelectArray(
          eventsViolence.data,
          "name",
          "_id"
        );
        const sortedEvents = [...auxViolence].sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        setListEvents(sortedEvents);

        setEventsViolence();
      }
    }
  }, [municipalities, eventsViolence, eventsHealth]);

  const sortedData = (list) =>
    list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  useEffect(() => {
    if (requestDataMap.data) {
      const dataTransformed = transformToMap(requestDataMap.data.data);
      if (dataTransformed.features.length === 0) {
        setMessage({
          type: "warning",
          title: "Sin resultados",
          description:
            "No se encontraron eventos con los filtros seleccionados.",
        });
      }
      setMapData(dataTransformed);
    }
    if (getAllDocuments.data) {
      const auxData = getAllDocuments.data.data?.items;
      setListLastFiles(auxData);
      getAllDocuments.setData();
    }
    if (getAnalysis.data) {
      const transformedList = getAnalysis.data.data.items.reduce(
        (acc, group) => {
          // Extraer el campo 'analysis' de cada item dentro de cada grupo
          const items = group.items.map((item) => item.analysis);
          return acc.concat(items);
        },
        []
      );
      const auxSorted = sortedData(transformedList).slice(0,10)

      setListAnalysis(auxSorted);

      getAnalysis.setData();
    }
  }, [requestDataMap.data, getAllDocuments.data, getAnalysis.data]);

  const validateForm = (values) => {
    // Validar que al menos uno de los tipos de eventos esté seleccionado
    if (
      !(values.violence && values.violence.length > 0) &&
      !(values.health && values.health.length > 0)
    ) {
      setMessage({
        type: "error",
        title: "Error",
        description: "Por favor, selecciona al menos un tipo de evento.",
      });

      return false;
    }
    // form.validateFields(["dateRange", "zones"]);;
    return true;
  };

  const onclick = async (values) => {
    if (validateForm(values)) {
      const dataFilter = {
        date: {
          startDate: values.dateRange[0].format("YYYY-MM-DD"),
          endDate: values.dateRange[1].format("YYYY-MM-DD"),
        },
        events: [
          ...(values.violence && values.violence.length > 0
            ? values.violence.map((item) => item.value)
            : []),
          ...(values.health && values.health.length > 0 ? values.health : []),
        ],
        municipalities: values.zones.map((item) => item.value),
      };

      requestDataMap.request(dataFilter);
    }
  };

  const onHandleClickChart = () => navigate("/statement_indicators");

  const onHandleCardClick = (item) => {
    // eslint-disable-next-line no-underscore-dangle
    navigate(`/comparative_analysis/${item._id}`);
    
  }
  const onHandleFileClick = (item) => {
    // eslint-disable-next-line no-underscore-dangle
    navigate(`/document/${item._id}`);
    
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col lg:flex-row lg:space-x-4 pt-5 mx-5">
        <div className="flex flex-col lg:ml-5 mt-5 w-full lg:w-7/12">
          <div className="mb-5">
            <strong className="text-lightBlue2 text-xl">
              ¡Hola, {currentUser?.firstName} {currentUser?.lastName}!
            </strong>
            <p>Accede rápidamente a tus últimos datos cargados</p>
          </div>
          <HomeLastFiles className="h-full" listLastFiles={listLastFiles} handleClick={onHandleFileClick} />
        </div>
        <HomePreviewIndicatorChart
          onHandleClickChart={onHandleClickChart}
          className="w-2/3 lg:w-5/12 mt-5 lg:mt-0 "
        />
      </div>
      <HomeLastAnalysis className="mt-2 mx-5" listLastAnalysis={listAnalysis} handleCardClick={onHandleCardClick} />
      <Row className="justify-between">
        <Col xs={24} md={12} lg={12} xl={16}>
          <MapCard data={mapData} />
        </Col>
        <Col xs={24} md={8} lg={12} xl={8}>
          <Form form={form} name="home-form">
            <HomeForm
              onSelectItemZone={handleItemZone}
              onSelectItemEvent={handleItemEvent}
              zones={zonesSelected}
              events={eventsSelected}
              municipalities={municipalitiesList}
              violenceList={listEvents}
              healthList={listHealthEvents}
              form={form}
              onClick={() => onclick(form.getFieldsValue())}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default HomeTemplate;
