// enums.js
const FileStatus = Object.freeze({
  UPLOADED: "uploaded", // File has been uploaded
  PENDING: "pending", // File is pending to be processed
  READY: "ready", // File is ready to be processed with Pandas Profiling
  REJECTED: "rejected", // File has been rejected
});

const ProposalStatus = Object.freeze({
  PROPOSED: "proposed",
  APPROVED: "approved",
  REJECTED: "rejected",
  SUGGESTED: "suggested",
});

const USERS_STATUS_NOT_ALLOWED = Object.freeze(["Pendiente"]);

export { FileStatus, ProposalStatus, USERS_STATUS_NOT_ALLOWED };
