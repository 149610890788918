import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";

const StackedBarChart = ({ dataChart, title, titleX, titleY }) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        window.requestAnimationFrame(() => {
          Plotly.Plots.resize(chartRef.current);
        });
      }
    };

    // Set initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useImageChart({ plotRef, chartType: "stackedBar" });

  const returnDataChart = () => {
    if (!Array.isArray(dataChart)) {
      return [
        {
          type: "bar",
          x: ["Category A", "Category B", "Category C"],
          y: [5, 6, 7], // Low range values
          name: "Low",
          marker: {
            color: "rgba(55,128,191,0.6)",
            width: 1,
          },
        },
      ];
    }
    return dataChart;
  };
  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        ref={plotRef}
        data={returnDataChart()}
        layout={{
          title,
          barmode: "stack",
          bargap: 0.05,
          autosize: true,
          xaxis: {
            title: titleX,
          },
          yaxis: {
            title: titleY,
          },
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onInitialized={(_, graphDiv) => {
          plotRef.current = graphDiv;
        }}
      />
    </div>
  );
};

export default StackedBarChart;
