/* eslint-disable indent */
import { CalendarOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Card from "../../atoms/Card/Card";
import AppButton from "../../atoms/Buttons/AppButton";
import { localeTime } from "../../../helpers/transformUTCtoColombiaTime";

const { Text } = Typography;

const HomeLastFiles = ({ className, listLastFiles = [],handleClick }) => (
  <div className={className}>
    <strong>Últimos datos cargados</strong>
    <div className="flex py-3 px-1 space-x-4 overflow-auto">
      {Array.isArray(listLastFiles) && listLastFiles.length > 0
        ? listLastFiles.map((item) => (
            <Card className="min-w-48  bg-white">
              <Text className="font-bold" ellipsis={{ tooltip: item.name }}>
                {item.name}
              </Text>
              {/* <Text ellipsis>{item.name}</Text> */}
              <div className="flex mt-2">
                <div className="flex items-start h-full">
                  <CalendarOutlined className="text-lightBlue2 mt-1" />
                </div>
                <div className="ml-2">
                  <span className="font-medium">Fecha y hora de subida:</span>
                  <p>{localeTime(item.createdAt)}</p>
                  <span className="font-medium">Código:</span>
                  <p>{item.code}</p>
                </div>
              </div>
              <div className="flex w-full justify-center mt-2">
                <AppButton className="bg-aquamarineBlue !py-0" title="Ver" onClick={()=>handleClick(item)} />
              </div>
            </Card>
          ))
        : "No has subido archivos"}
    </div>
  </div>
);

export default HomeLastFiles;
