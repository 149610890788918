import axios from "axios";

const fetchingFiles = async (listToRequest) => {
  let auxListToRequest = listToRequest
  if(!Array.isArray(listToRequest)) auxListToRequest = [listToRequest]
  const axiosArray = auxListToRequest.map((item) => {
    const config = {
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": item.file.type,
      },
    };
    return axios.put(item.url, item.file?.originFileObj, config);
  });
  const listResult = Promise.allSettled(axiosArray).then((results) =>
    results.map((result) => {
      if (result.status === "fulfilled") {
        return true;
      }
      return false;
    })
  );
  return listResult;
};

export default fetchingFiles;
