import React, { useEffect, useState } from "react";
import { Tabs, Spin } from "antd";
import { useParams } from "react-router-dom";
import DataPreviewTemplate from "../../components/templates/DataPreviewTemplate/DataPreviewTemplate";
import document from "../../api/document";
import useApi from "../../api/useApi";
import GeneralInformation from "../../components/templates/GeneralInformation/GeneralInformation";
import { FileStatus } from "../../helpers/enums";

// import validatePermissionUserOwner from "../../helpers/validatePermissionOwnerUser";

const TabsDocument = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const getParentDoc = useApi(document.getDescriptionDoc);
  const [idParent, setIdParent] = useState();
  const [dataDescription, setDataDescription] = useState({});
  const [title, setTitle] = useState();
  const [itemDefault, setItemDefault] = useState(`${params.key}-1`);
  const [items, setItems] = useState([]);
  // const { IsOwner } = validatePermissionUserOwner;

  const getInfoDoc = () => {
    getDescriptionDoc.request(params?.key);
  };

  useEffect(() => {
    getInfoDoc();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (getDescriptionDoc.data) {
      const aux = getDescriptionDoc.data.data;
      let auxId = params?.key;
      setItemDefault("1");
      if (aux?.status === FileStatus.UPLOADED) {
        setItemDefault("2");
      }
      if (aux.isChild) {
        auxId = aux.parentId;
        getParentDoc.request(aux.parentId);
      } else {
        setTitle(aux.name);
      }
      setTitle(aux.name);
      // if (aux.multipleSheets) {
      //   if (aux.listSheets.length > 0) {
      //     setItemDefault("2");
      //   }
      // }
      setDataDescription(aux);
      setIdParent(auxId);
      setLoading(false);
      getDescriptionDoc.setData();
    }
    if (getParentDoc.data) {
      const aux = getParentDoc.data.data;
      setTitle(aux.name);
      if (aux.multipleSheets) {
        if (aux.listSheets.length > 0) {
          setItemDefault("2");
        }
      }
      getParentDoc.setData();
    }
  }, [getDescriptionDoc.data, getParentDoc.data]);

  useEffect(() => {
    if (getParentDoc.error) {
      setTitle("Documento no encontrado");
      getParentDoc.setData();
    }
  }, [getParentDoc.error, getDescriptionDoc.error]);

  useEffect(() => {
    if (dataDescription.name && idParent) {
      setItems([
        {
          key: "1",
          label: "Vista previa",
          children: (
            <div className="shadow-body ml-[3px] relative top-[-1px]">
              <DataPreviewTemplate dataInfo={dataDescription} />
            </div>
          ),
          destroyInactiveTabPane: true,
        },
        {
          key: "2",
          label: "Información general",
          children: (
            <div className="shadow-body ml-[3px] relative top-[-1px]">
              <GeneralInformation idDoc={idParent} />
            </div>
          ),
        },
      ]);
    }
    
  }, [params.key, dataDescription, idParent]);

  return !loading ? (
    <div className="min-h-screen flex flex-col pt-10 px-2 md:px-20">
      <div className="shadow-body px-5 pb-5 relative rounded-large">
        <div className="flex w-full justify-center items-center py-5">
          <strong className="text-lightBlue2 text-2xl">
            {/* {dataDescription?.name} */}
            {title}
          </strong>
        </div>
        <Tabs type="card"  items={items} activeKey={itemDefault} onChange={setItemDefault}/>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-60">
      <Spin />
    </div>
  );
};

export default TabsDocument;
