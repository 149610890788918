/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dropdown,
  FloatButton,
  Space,
  Spin,
  Typography,
  Tag,
  Menu,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  EllipsisOutlined,
  ReloadOutlined,
  CloseOutlined,
  CheckOutlined,
  DownOutlined,
} from "@ant-design/icons";
// import TableTemplate from "../TableTemplate";
import DataPreviewFilter from "./DataPreviewFilter";
import ModalConfirm from "../../molecules/Modal/ModalConfirm";
import ColumnTemplate from "../ColumnTemplate/ColumnTemplate";
import AppModal from "../../molecules/Modal/Modal";
import DataPreviewInformation from "./DataPreviewInformation";
import useApi from "../../../api/useApi";
import document from "../../../api/document";
import isObject from "../../../helpers/isObject";
// import formatSize from "../../../helpers/formatSize";
import { useAuth } from "../../../contexts/AuthContext";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import transformToDateFormat from "../../../helpers/transformToDateFormat";
import transformToTree from "../../../helpers/transformToTree";
import transformToTreeSelectRegions from "../../../helpers/transformToTreeSelectRegions";
import AssociateIndicatorForm from "../../organisms/AssociateIndicatorForm/AssociateIndicatorForm";
import IndicatorRequest from "../../../api/indicator";
import IframeView from "../../organisms/Iframeview/IframeView";
import ValidatePermissions from "../../../helpers/ValidatePermissions";
import PdfViewer from "../../organisms/PdfView/pdfView";
import downloadFile from "../../../helpers/downloadFile";
import DataProfiling from "../DataProfiling/DataProfiling";
import processColumns from "../../../helpers/processColumns";
import SheetFormTemplate from "../SheetFormTemplate/SheetFormTemplate";
import transformColumns from "../../../helpers/transformColumns";
import SelectSheet from "../../molecules/SelectSheet/SelectSheet";
import ROUTES from "../../../constant/router";
import TableJoined from "../TableTemplate/TableJoined";
// import { dataFile } from "../../../assets/Json/fakeDataFile";
import ShareFileTemplate from "../ShareFileTemplate/ShareFileTemplate";
import { FileStatus, ProposalStatus } from "../../../helpers/enums";
import listCodification from "../../../constant/listCodification";
import SelectWhite from "../../atoms/Select/AppSelectWhite";
import MapRequest from "../../../api/MapRequest";


// import { addPropsToTree } from "../../../helpers/depthArray";

const { Text } = Typography;

const DataPreviewTemplate = ({ dataInfo }) => {
  const params = useParams(); // Extracción correcta del key

  const listActiveFilter = [
    {
      id: 1,
      name: "Filtro 1",
      originColumn: "VIGENCIA",
      filterType: "Contiene",
      text: "20",
    },
    {
      id: 2,
      name: "Filtro 2",
      originColumn: "SEXO",
      filterType: "Igual a",
      text: "Mujer",
    },
    {
      id: 3,
      name: "Filtro 3",
      originColumn: "ETNICA",
      filterType: "No contiene",
      text: "Ninguna",
    },
  ];

  const listDataTypes = [
    {
      id: 1,
      value: "texto",
      label: "Texto",
    },
    {
      id: 2,
      value: "numero",
      label: "Numérico",
    },
    {
      id: 3,
      value: "fecha",
      label: "Fecha",
    },
  ];

  const [webSourceActive, setWebSourceActive] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeButton, setActiveButton] = useState("information");
  // const [originColumn, setOriginColumn] = useState("Ninguna");
  const [actionFilterView, setActionFilterView] = useState("view");
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [metadataFinal, setMetaDataFinal] = useState({
    total: 10,
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [dataDoc, setDataDoc] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState({
    table: false,
    infoDoc: false,
    description: false,
    reloadTable: false,
  });
  const [listColumns, setListColumns] = useState([]);
  const [listColumnsFinal, setListColumnsFinal] = useState([]);
  const [columnsSelect, setColumnsSelect] = useState([]);
  const [dataDescription, setDataDescription] = useState([]);
  const [dataModalDescription, setDataModalDescription] = useState();
  const [dataDesColumn, setDataDecColumn] = useState({});
  const [data, setData] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [infoDoc, setInfoDoc] = useState({});
  const [clickedDropdown, setClickedDropdown] = useState(false);
  const [columnNameSelected, setColumnNameSelected] = useState("");
  const [modalIndicator, setModalIndicator] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const [listGroupIndicator, setListGroupIndicator] = useState([]);
  const [modeSelected, setModeSelected] = useState("none");
  const [suggestSelected, setSuggestSelected] = useState("none");
  const [urlView, setUrlView] = useState(null);
  // get data content to file
  const getDataDoc = useApi(document.getDataDocument);
  const getDataFinal = useApi(document.getDataDocument);

  const updateDescriptionColum = useApi(document.updateDescriptionColum);
  const updateManyColumns = useApi(document.updateManyColumns);
  const updateDescriptionDoc = useApi(document.updateDescriptionDoc);
  // get columns to file
  const getColumnsFile = useApi(document.getColumnsFile);
  // get metadata to file
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  // get parent metadata to file
  const getParentDoc = useApi(document.getDescriptionDoc);
  const isFavoriteDocument = useApi(document.UpdateFavoriteDocument);

  // const getMunicipalities = useApi(DocumentRequest.getMunicipalities);
  const getMunicipalityForRegion = useApi(MapRequest.getMunicipalities);
  const getIndicators = useApi(IndicatorRequest.getIndicatorGroups);
  const createPostulation = useApi(IndicatorRequest.createPostulateIndicator);
  const createManyPostulation = useApi(
    IndicatorRequest.createManyPostulationIndicator
  );
  const suggestionsIndicator = useApi(
    IndicatorRequest.updatedIndicatorSuggestions
  );
  const deletePostulation = useApi(IndicatorRequest.deletePostulateIndicator);
  const downloadPdf = useApi(document.downloadPdf);
  const downloadDocument = useApi(document.downloadDocument);
  const { setMessage } = useAuth();
  const [isOwner, setIsOwner] = useState(false);
  const [isNoFile, setIsNoFile] = useState(false);
  const [pdfSourceActive, setPdfSourceActive] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [columnPostulation, setColumnPostulation] = useState();
  const [columnEditPostulation, setColumnEditPostulation] = useState();
  const [listSheet, setListSheet] = useState([]);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multipleEdit, setMultipleEdit] = useState(false);
  const [proposalSelected, setProposalSelected] = useState([]);
  const [changeHeader, setChangeHeader] = useState(false);

  const reloadData = () => {
    if (!isNoFile && activeButton !== "description") {
      

      if (dataModalDescription?.status !== FileStatus.UPLOADED) {
        setLoading((prev) => ({ ...prev, table: true }));
        getDataDoc.request(params?.key, metadata.pageSize, metadata.page);
      }
    }
    // getDataDoc.setData(dataFile);
  };
  const getInfoDoc = () => getDescriptionDoc.request(params?.key);

  const refreshListColumns = () => getColumnsFile.request(params?.key);

  useEffect(() => {
    if (activeButton !== "description") {
      setLoading((prev) => ({ ...prev, infoDoc: true }));
      // getInfoDoc();
      getMunicipalityForRegion.request();
      // getMunicipalities.request(52);
      getIndicators.request();
      // getDescriptionDoc.request(params?.key);
      // console.log("segunda peticion")
    }
  }, []);

  useEffect(() => {
    
    getInfoDoc();
  }, [params?.key]);

  useEffect(() => {
    if (activeButton === "description") {
      setSelectedRowKeys([]);
    }

    if (getColumnsFile.data) {
      const auxDataColumns = getColumnsFile.data.data;
      if (
        activeButton === "description" &&
        Array.isArray(auxDataColumns) &&
        auxDataColumns.length > 0
      ) {
        const auxCol = auxDataColumns
          .filter((item) => item.name !== "UUID")
          .map((item) => ({
            ...item,
            key: item.name,
          }));
        setData(auxCol);
      }
      setLoading((prev) => ({ ...prev, table: false }));
      getColumnsFile.setData();
    }
  }, [activeButton, getColumnsFile.data]);

  useEffect(() => {
    if (dataInfo) {
      const auxData = dataInfo;

      setIsOwner(auxData.isOwner);
      if (auxData.fileExtension === ".pdf") {
        setPdfSourceActive(true);
        setUrlView(auxData.urlPdf);
        setIsNoFile(true);
      }
      if (auxData.fileExtension === "web") {
        setWebSourceActive(true);
        setUrlView(auxData.urlWebSource);
        setIsNoFile(true);
      }
      if (
        auxData.fileExtension === ".xlsx" ||
        auxData.fileExtension === ".csv" ||
        auxData.fileExtension === ".xls"
      ) {
        if (auxData.isChild) {
          getParentDoc.request(auxData.parentId);
        }
        if (auxData.multipleSheets) {
          if (auxData.sheetsList.length > 0) {
            const aux = transformToSelectArray(
              auxData.sheetsList,
              "name",
              "_id",
              false
            );
            setListSheet(aux);
            // getDescriptionDoc.request(auxData.listSheets[0]._id);
            const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(
              ":key",
              auxData.sheetsList[0]._id
            );
            navigate(path);
          }
        }
        // }else {
        //   getInfoDoc();
        // }
      }
      // console.log("primera peticion",params?.key);
      // getDescriptionDoc.request(params?.key);
    }
  }, [dataInfo]);

  // const initialLoad = useRef(true);

  // useEffect(() => {
  //   // reloadData();
  //   if (initialLoad.current) {
  //     initialLoad.current = false;
  //   } else {
  //     console.log("reloadData called 3");
  //     reloadData();
  //   }
  // }, [metadata.page, metadataFinal.page]);

  // const openModal = () => setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
    setClickedDropdown(false);
  };

  const activeSection = (nameButton) => {
    if (
      !isNoFile &&
      (nameButton === "share"||nameButton === "profiling") &&
      dataModalDescription?.status !== FileStatus.READY
    ) {
      setMessage({
        type: "info",
        title: "Sin Columnas",
        description:
          "El archivo no está procesado, elija el encabezado de la hoja.",
      });
    } else {
      setActiveButton(nameButton);
    }
  };

  /*   const items = [
    {
      label: "Editar descripción",
      key: "0",
    },
    {
      label: "Agregar nuevo filtro",
      key: "1",
    },
  ]; */

  /*   const handleClickItemDropdown = (nameColumn, { key }) => {
    console.log(
      "🚀 ~ handleClickItemDropdown ~ dataDescription:",
      dataDescription
    );
    setOriginColumn(nameColumn);
    setClickedDropdown(true);
    if (key === "1") {
      setActionFilterView("add");
      activeSection("filter");
    }
    if (key === "0") {
      const auxValues = dataDescription.filter(
        (val) => val.name === nameColumn
      );
      setDataDecColumn(auxValues[0]);
      openModal();
    }
  }; */

  const handlerSubmitDescription = (val) => {
    const {
      name,
      description,
      municipalityColumn,
      dateColumn,
      headerStart,
      headerEnd,
      dataStart,
      dataEnd,
    } = val;
    let munColumnAux = municipalityColumn || null;
    let dateColumnAux = dateColumn || null;
    const municipality = val?.municipality || [];
    let municipalityCode = null;
    const dateRange = val?.dateRange
      ? transformToDateFormat(val?.dateRange)
      : null;
    let urlWebSource = val?.urlWebSource || null;
    const keyWords = val?.keyWords || [];
    const cutOffDate = val?.cutOffDate
      ? transformToDateFormat(val?.cutOffDate)
      : null;
    const headerSize = [headerStart, headerEnd];
    const dataSize = dataStart && dataEnd ? [dataStart, dataEnd] : [];

    if (municipalityColumn === "0") {
      munColumnAux = null;
    } else {
      municipalityCode = val?.municipalityCode || null;
    }

    if (dateColumn === "0") {
      dateColumnAux = null;
    }
    if (dataModalDescription.fileExtension === "web") {
      urlWebSource = dataModalDescription.urlWebSource;
    }
    let auxData = {
      name,
      description,
      urlWebSource,
      keyWords,
      cutOffDate,
      locationData: {
        municipalityColumn: munColumnAux,
        municipalityCode,
        municipality,
        dateColumn: dateColumnAux,
        dateRange,
      },
    };
    if (
      dataModalDescription.fileExtension === ".xlsx" ||
      dataModalDescription.fileExtension === ".csv" ||
      dataModalDescription.fileExtension === ".xls"
    ) {
      auxData = {
        ...auxData,
        headerSize,
        dataSize,
      };
      setLoading((prev) => ({ ...prev, table: true }));
      if (dataModalDescription.headerSize !== headerSize) {
        setChangeHeader(true);
      }
    }
    if (dataModalDescription.isChild) {
      const { copySheets } = val;
      if (!copySheets) {
        updateDescriptionDoc.request(params?.key, auxData);
      } else if (Array.isArray(copySheets) && copySheets[0] === "all") {
        const auxListSheet = listSheet.map((item) => item.value);
        updateDescriptionDoc.request(params?.key, auxData, auxListSheet);
      } else {
        updateDescriptionDoc.request(params?.key, auxData, copySheets);
      }
    } else {
      updateDescriptionDoc.request(params?.key, auxData);
    }
    setLoading((prev) => ({ ...prev, description: true }));
  };

  const onHandleResetConfig = () => {
    const headerSize = null;
    const dataSize = null;
    const status = FileStatus.PENDING;
    const auxData = {
      headerSize,
      dataSize,
      status,
    };
    setChangeHeader(true);
    updateDescriptionDoc.request(params?.key, auxData);
  };

  const onDownloadDocument = () => {
    if (infoDoc.ext === ".pdf") {
      downloadPdf.request(params?.key);
    } else {
      downloadDocument.request(params?.key);
    }
  };

  const onFavoriteDocument = () => {
    isFavoriteDocument.request(params?.key, { isFavorite: !isFavorite });
  };

  /* const renderDropdown = (name) => (
    <div className="flex w-full text-center justify-between">
      <div className="flex flex-col justify-center w-full">
        <p className="self-center">{name}</p>
      </div>
      <Dropdown
        menu={{
          items,
          onClick: (e) => handleClickItemDropdown(name, e),
        }}
        trigger={["click"]}
      >
        <button
          className="flex flex-col"
          onClick={(e) => e.preventDefault()}
          type="button"
          aria-label="Save"
        >
          <div className="">
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "17px", transform: "scale(1.5, 1  )" }}
            />
          </div>
        </button>
      </Dropdown>
    </div>
  ); */

  const onEditDescColumn = (record) => {
    setMultipleEdit(false);
    setModalIndicator(false);
    setShowModal(true);
    setDataDecColumn(record);
  };

  const onHandleSaveDesc = (values) => {
    if (multipleEdit) {
      const auxValues = {
        columns: selectedRowKeys,
        actionType: "DataType",
        action: values,
      };
      updateManyColumns.request(params?.key, auxValues);
    } else {
      const auxValues = {
        ...values,
        name: dataDesColumn?.name,
      };
      updateDescriptionColum.request(params?.key, auxValues);
    }
    setLoading((prev) => ({ ...prev, table: true }));
  };
  const onChangeDataType = (record, values) => {
    const auxValues = {
      ...values,
      name: record.name,
    };
    updateDescriptionColum.request(params?.key, auxValues);
    setLoading((prev) => ({ ...prev, table: true }));
  };

  const onChangeIsSensitive = (e, record) => {
    const dataAux = {
      name: record.name,
      isSensitiveData: e.target.checked,
    };
    updateDescriptionColum.request(params?.key, dataAux);
    setLoading((prev) => ({ ...prev, table: true }));
  };

  const onAssociatedIndicator = (record) => {
    setMultipleEdit(false);
    setColumnNameSelected(record.name);
    setModalIndicator(true);
    setShowModal(true);
    setColumnPostulation(record);
  };
  const onEditIndicator = (record) => {
    setColumnNameSelected(record.name);
    const aux = record.idExtractionState.filter(
      (item) =>
        item.state === ProposalStatus.PROPOSED ||
        item.state === ProposalStatus.REJECTED
    );
    const initialValues = {
      // Extraemos directamente los child IDs desde indicatorTrace
      indicator: aux.map((item) => item.indicatorTrace[1]),

      // Mapeamos los valores de parent y children con su respectivo tipo
      parentValues: aux.map((item) => ({
        parent: item.indicatorTrace[0], // parentId del primer elemento en indicatorTrace
        childs: [item.indicatorTrace[1]], // Array de childIds, en este caso un único elemento
      })),
    };

    setColumnPostulation(record);
    setColumnEditPostulation(initialValues);
    setModalIndicator(true);
    setShowModal(true);
  };
  const handlerMultipleAssociatedIndicator = (parentValue) => {
    if (parentValue.children.length > 0) {
      const request = {
        columns: selectedRowKeys,
        proposals: {
          fileId: params?.key,
          indicatorsTrace: parentValue.children, // Solo los nuevos elementos seleccionados
        },
      };
      createManyPostulation.request(request);
    }
  };
  const handleAssociatedIndicator = (values, parentValue, isEdit) => {
    if (values) {
      if (multipleEdit) {
        handlerMultipleAssociatedIndicator(parentValue);
      } else {
        let filteredParentValue = parentValue.children; // Lista de valores seleccionados
        let removedValues = []; // Lista de valores eliminados

        // Si estamos en modo edición (isEdit = true)
        if (isEdit) {
          const initialIndicatorIds = columnEditPostulation.indicator; // IDs iniciales de indicadores

          // Filtrar parentValue para excluir aquellos que ya están en initialValues
          filteredParentValue = parentValue.children.filter(
            (item) =>
              !columnEditPostulation.parentValues.some(
                (initialItem) =>
                  initialItem.parent === item.parentId &&
                  initialItem.childs[0] === item.childs[0]
              )
          );

          // Identificar los valores que estaban en initialValues pero ya no están en parentValue
          removedValues = columnEditPostulation.parentValues.filter(
            (item) =>
              !parentValue.children.some(
                (newItem) =>
                  newItem.parentId === item.parent &&
                  newItem.childs[0] === item.childs[0]
              )
          );

          // Si hay valores eliminados, enviar una solicitud para eliminarlos
          if (removedValues.length > 0) {
            const auxPos = columnPostulation.idExtractionState
              .filter((item) =>
                removedValues.some(
                  (newItem) =>
                    item.indicatorTrace[0] === newItem.parent &&
                    item.indicatorTrace[1] === newItem.childs[0]
                )
              )
              .filter(
                (item) =>
                  item.state === ProposalStatus.PROPOSED ||
                  item.state === ProposalStatus.REJECTED
              )
              .map((item) => item.idProposal); // Extraer IDs de las postulaciones a eliminar

            const dataDelete = {
              ids: auxPos, // Lista de IDs a eliminar
            };

            // Realizar la petición de eliminación
            deletePostulation.request(dataDelete);
          }
        }

        // Si hay nuevos elementos seleccionados, enviar solicitud de creación
        if (filteredParentValue.length > 0) {
          const request = {
            fileId: params?.key,
            columnName: columnNameSelected,
            indicatorsTrace: filteredParentValue, // Solo los nuevos elementos seleccionados
            state: "proposed", // Estado inicial de la postulación
          };

          // Realizar la petición de creación
          createPostulation.request(request);
        }
      }
      // Actualizar el estado de la tabla y cerrar el modal
      setLoading((prev) => ({ ...prev, table: true }));
      closeModal();
      setModalIndicator(false);
    }
    // Limpieza de estados para el siguiente uso
    setColumnNameSelected();
    setColumnPostulation();
  };

  const handleDeletePostulation = (record, proposal) => {
    setColumnPostulation(record);
    setProposalSelected(proposal);
    setModalActiveConfirm(true);
  };

  const EliminarCloseModal = () => {
    setModalActiveConfirm(false);

    if (proposalSelected.state !== ProposalStatus.APPROVED) {
      const auxData = {
        ids: [proposalSelected.idProposal],
      };
      deletePostulation.request(auxData);
    } else {
      setMessage({
        type: "error",
        title: "Error",
        description: "No se puede eliminar una postulación aprobada.",
      });
    }
    setColumnPostulation();
    setColumnPostulation();
  };
  const closeConfirmModal = () => {
    setModalActiveConfirm(false);
  };

  const RenderModalDeletePostulations = () => {
    if (modalActiveConfirm) {
      return (
        <div>
          <ModalConfirm
            centered
            okText="Eliminar"
            handleCancel={closeConfirmModal}
            handleOK={EliminarCloseModal}
            closeModal={closeConfirmModal}
            modalActive={modalActiveConfirm}
            text={`¿Está seguro de que desea eliminar la postulación a ${proposalSelected.childName} de esta columna?, Se eliminará solamente la postulación que no hayan sido aceptadas.`}
          />
        </div>
      );
    }
    return null;
  };

  const handleClickItemDropdown = (e, record) => {
    if (e.key === "1") {
      onEditIndicator(record);
    }
  };

  const handleDescriptionClicked = () => {
    const ListColumnsDescription = [
      {
        title: "Nombre Columna",
        dataIndex: "name",
        key: "name",
        align: "center",
        width: 400,
      },
      {
        title: "Tipo de Dato",
        dataIndex: "dataType",
        key: "dataType",
        align: "center",
        width: 100,
        render: (text, record) => {
          if (isOwner) {
            return (
              <div className="">
                <SelectWhite
                  name="dataType"
                  className="w-full font-semibold text-color_text text-textTable"
                  // selectClass="formBgWhite "
                  options={listDataTypes}
                  onChange={(value) =>
                    onChangeDataType(record, { dataType: value })
                  }
                  valueInitial={record.dataType}
                />
              </div>
            );
          }
        },
      },
      {
        title: "Dato Sensible",
        dataIndex: "isSensitiveData",
        key: "isSensitiveData",
        align: "center",
        render: (text, record) => {
          if (isOwner) {
            return (
              <div className="flex justify-center">
                <Checkbox
                  key={record.name}
                  checked={text}
                  onChange={(e) => onChangeIsSensitive(e, record)}
                />
              </div>
            );
          }
          return (
            <div className="flex justify-center">
              <Checkbox key={record.name} checked={text} disabled />
            </div>
          );
        },
      },
      {
        title: "Descripción",
        dataIndex: "description",
        key: "description",
        align: "center",
        width: 400,
      },
      {
        title: "Indicador Asociado",
        dataIndex: "idExtractionState",
        key: "idExtractionState",
        align: "center",
        render: (text, record) => {
          const items = [{ key: 1, label: "Editar" }];

          if (!text || text.length === 0) {
            return isOwner ? (
              <Button
                className="text-lightBlue2 shadow-xl hover:text-lightBlue hover:shadow-none my-2"
                icon={<PlusOutlined />}
                onClick={() => onAssociatedIndicator(record)}
                shape="circle"
                size="small"
              />
            ) : null;
          }
          return (
            <div className="flex items-center justify-between w-full">
              {/* Contenedor de las etiquetas sin restricciones de altura */}
              <div className="flex flex-wrap gap-2 pt-2">
                {text.map((item) => (
                  <Tag
                    key={item.idProposal}
                    className={`whitespace-nowrap ${item.state === ProposalStatus.SUGGESTED ? "italic bg-dark_gray" : "bg-white_custom text-[#000000]"}`}
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      item.state === ProposalStatus.APPROVED
                        ? "success"
                        : item.state === ProposalStatus.REJECTED
                          ? "error"
                          : "default"
                    }
                    closable={item.state !== ProposalStatus.APPROVED} // Solo closable si el estado es "rejected"
                    onClose={() =>
                      item.state !== ProposalStatus.APPROVED &&
                      handleDeletePostulation(record, item)
                    } // Manejar el cierre solo si es "rejected"
                  >
                    {item.childName}
                  </Tag>
                ))}
              </div>
              {isOwner && (
                <div className="flex items-center justify-center flex-shrink-0 ml-2">
                  <Dropdown
                    menu={{
                      items,
                      onClick: (e) => handleClickItemDropdown(e, record, text),
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      className="text-lightBlue2 shadow-xl hover:text-lightBlue hover:shadow-none my-2"
                      icon={<EllipsisOutlined />}
                      shape="circle"
                      size="small"
                    />
                  </Dropdown>
                </div>
              )}
            </div>
          );
        },
        className: "w-auto",
      },

      {
        title: "Editar",
        key: "acciones",
        align: "center",
        ellipsis: true,
        render: (text, record) => (
          <div className="flex space-x-2 justify-center">
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
              icon={!loading.description ? <EditOutlined /> : <Spin />}
              onClick={() => onEditDescColumn(record)}
            />
          </div>
        ),
        className: "",
      },
    ];
    // eslint-disable-next-line no-debugger
    const listAux = isOwner
      ? ListColumnsDescription
      : ListColumnsDescription.filter((item) => item.key !== "acciones");

    // setData(dataDescription);// agrega la metadata de las columna a la tabla
    if (
      dataModalDescription &&
      dataModalDescription.status === FileStatus.READY
    ) {
      setListColumns(listAux);
      setLoading((prev) => ({ ...prev, table: true }));
      refreshListColumns();
      activeSection("description");
    } else {
      setMessage({
        type: "info",
        title: "Sin Columnas",
        description:
          "El archivo no está procesado, elija el encabezado de la hoja para mostrar las columnas.",
      });
    }
  };

  useEffect(() => {
    if (getDescriptionDoc.data) {
      const auxData = getDescriptionDoc.data.data;
      setIsOwner(auxData.isOwner);
      if (
        (auxData.fileExtension === ".xlsx" ||
          auxData.fileExtension === ".csv" ||
          auxData.fileExtension === ".xls") &&
        auxData.status === FileStatus.UPLOADED
        &&
        auxData.listSheets.length === 0
      ) {
        setMessage({
          type: "info",
          title: "Archivo Procesado",
          description:
            "El documento se está procesando, por favor intente más tarde.",
        });
        getDescriptionDoc.setData();
      } else if (auxData.multipleSheets) {
        if (auxData.sheetsList.length > 0) {
          const aux = transformToSelectArray(
            auxData.sheetsList,
            "name",
            "_id",
            false
          );
          setListSheet(aux);
          const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(
            ":key",
            auxData.sheetsList[0]._id
          );
          window.location.assign(path);
        } else {
          setMessage({
            type: "info",
            title: "Sin hojas",
            description:
              "El documento se está procesando, por favor intente más tarde.",
          });
          getDescriptionDoc.setData();
        }
      } else {
        if (activeButton === "information") {
          if (auxData.fileExtension === "web") {
            setWebSourceActive(true);
            setIsNoFile(true);
            setUrlView(auxData.urlWebSource);
          }

          if (auxData.fileExtension === ".pdf") {
            setWebSourceActive(false);
            setIsNoFile(true);
            setPdfSourceActive(true);
            setUrlView(auxData.urlPdf);
          }

          setInfoDoc({
            headers:
              auxData.headerSize?.length > 0
                ? `Fila ${auxData.headerSize[0]} a Fila ${auxData.headerSize[1]}`
                : "Ninguna",
            dataSize:
              auxData.dataSize?.length > 0
                ? `Fila ${auxData.dataSize[0]} a Fila ${auxData.dataSize[1]}`
                : "Ninguna",
            columnMunicipality: auxData.locationData?.municipalityColumn
              ? auxData.locationData.municipalityColumn
              : "Ninguna",
            columnDate: auxData.locationData?.dateColumn
              ? auxData.locationData.dateColumn
              : "Ninguna",
            municipalities:
              auxData.locationData?.municipality?.length > 0
                ? auxData.locationData.municipality.join(", ")
                : "Ninguno",
            dateRange: auxData.locationData?.dateRange
              ? auxData.locationData.dateRange
              : "Ninguna",
            keyWords:
              auxData.keyWords?.length > 0
                ? auxData.keyWords.join(", ")
                : "Ninguna",
          });
          setIsFavorite(auxData.isFavorite);
          setLoading((prev) => ({ ...prev, infoDoc: false }));
        }
        setDataModalDescription(auxData);
        if (isObject(auxData.metadata)) {
          if (
            Array.isArray(auxData.metadata.columns) &&
            auxData.metadata.columns.length > 0
          ) {
            // const auxCol = auxData.metadata.columns;

            const auxCol = auxData.metadata.columns
              .filter((item) => item.name !== "UUID")
              .map((item) => ({
                ...item,
                key: item.name,
              }));

            const aux = transformToSelectArray(auxCol, "name", "name");
            setColumnsSelect(aux);
            setDataDescription(auxCol);

            if (activeButton === "information") {
              if (auxCol.length > 0 && auxCol[0].name) {
                // TODO: change this line
                const auxC = processColumns(auxData.metadata.columns);

                setListColumns(auxC);

                setDataDoc((prev) => ({ ...prev, columns: auxC }));
                
              }
            }
          }
        }
        if (
          auxData.fileExtension === ".xlsx" ||
          auxData.fileExtension === ".csv" ||
          auxData.fileExtension === ".xls"
        ) {
          
          reloadData();
        }
        getDescriptionDoc.setData();
        setLoading((prev) => ({ ...prev, infoDoc: false }));
      }
    }

    if (getDataDoc.data) {
      const auxData = getDataDoc.data;
      

      if (auxData.data && activeButton !== "description") {
        const auxArray = auxData.data?.items;
        if (Array.isArray(auxArray) && auxArray.length > 0) {
          setData(auxArray);
          setDataDoc((prev) => ({ ...prev, data: auxArray }));
        }
        let auxColumns = [
          {
            title: " ",
            key: "index",
            align: "center",
            render: (text, record, index) => {
              // Calcula el índice global basado en la página y tamaño de página
              const globalIndex =
                (metadata.page - 1) * metadata.pageSize + index + 1;
              return (
                <div className="flex space-x-2 justify-center text-lightBlue2">
                  {globalIndex}
                </div>
              );
            },
            className: "",
          },
        ];

        if (
          dataModalDescription &&
          dataModalDescription?.status === FileStatus.PENDING
        ) {
          const auxColumnsInit = transformColumns(auxArray);
          auxColumns = [...auxColumns, ...auxColumnsInit];
          setListColumns(auxColumns);
          setDataDoc((prev) => ({ ...prev, columns: auxColumns }));
        } else {
          const auxColInitial = processColumns(
            dataModalDescription.metadata.columns
          );
          auxColumns = [...auxColumns, ...auxColInitial];

          setListColumns(auxColumns);
        }


        
        setMetaData({
          page: auxData.data.page,
          total: auxData.data.total,
          pageSize: auxData.data.page_size,
          totalPages: auxData.data.total_Pages,
        });
        

        if (changeHeader|| metadataFinal.page===1) {
          
          getDataFinal.request(
            params?.key,
            auxData.data.page_size,
            auxData.data.total_pages
          );
         
        } else {
          getDataFinal.request(
            params?.key,
            metadataFinal.pageSize,
            metadataFinal.page
          );
        }

        // setLoading((prev) => ({ ...prev, table: false }));
        getDataDoc.setData();
      }
    }
    if (getDataFinal.data) {
      const auxData = getDataFinal.data;

      if (auxData.data) {
        const auxArray = auxData.data?.items;
        if (Array.isArray(auxArray) && auxArray.length > 0) {
          setDataFinal(auxArray);
        }
        let auxColumns = [
          {
            title: " ",
            key: "index",
            align: "center",
            render: (text, record, index) => {
              // Calcula el índice global basado en la página y tamaño de página
              const globalIndex =
                (auxData.data.page - 1) * auxData.data.page_size + index + 1;
              return (
                <div className="flex space-x-2 justify-center text-lightBlue2">
                  {globalIndex}
                </div>
              );
            },
            className: "",
          },
        ];
        if (
          dataModalDescription &&
          dataModalDescription?.status === FileStatus.PENDING
        ) {
          const auxColFinal = transformColumns(auxArray);
          auxColumns = [...auxColumns, ...auxColFinal];
          setListColumnsFinal(auxColumns);
          setDataDoc((prev) => ({ ...prev, columns: auxColumns }));
        } else {
          const auxColFinal = processColumns(
            dataModalDescription.metadata.columns
          );
          auxColumns = [...auxColumns, ...auxColFinal];
          setListColumnsFinal(auxColumns);
          // setDataDoc((prev) => ({ ...prev, columns: auxColumns }));
        }
        if(auxData.data.page !== metadataFinal.page){
          setChangeHeader(false);
          
        }


        setMetaDataFinal({
          page: auxData.data.page,
          total: auxData.data.total,
          pageSize: auxData.data.page_size,
          totalPages: auxData.data.total_pages,
        });
        
        setLoading((prev) => ({ ...prev, table: false }));
        getDataFinal.setData();
      }
    }
    if (getParentDoc.data) {
      const auxData = getParentDoc.data.data;
      if (auxData?.sheetsList.length > 0) {
        const aux = transformToSelectArray(
          auxData.sheetsList,
          "name",
          "_id",
          false
        );

        setListSheet(aux);
      }
      getParentDoc.setData();
    }

    if (updateDescriptionColum.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La columna fue actualizada correctamente.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      closeModal();
      // TODO: call a func to reload data table desc
      // if (!clickedDropdown) {
      //   activeSection("description");
      //   refreshListColumns();
      // }
      activeSection("description");
      refreshListColumns();
      // getInfoDoc();
      updateDescriptionColum.setData();
    }

    if (getMunicipalityForRegion.data) {
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data.data,
        "subRegion",
        "subRegion",
        "Nariño",
        "municipalityName",
        "municipalityName"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }
    if (updateDescriptionDoc.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description:
          "La descripción de la hoja se ha actualizado correctamente",
      });
      setShowModal(false);
      setTimeout(() => {
        getInfoDoc();
        setLoading((prev) => ({ ...prev, description: false }));
      }, 3000);

      updateDescriptionDoc.setData();
    }
    if (getIndicators.data) {
      const aux = transformToTree(getIndicators.data.data);

      const sortedIndicator = [...aux].sort((a, b) =>
        a.title?.localeCompare(b.title)
      );
      setListGroupIndicator(sortedIndicator);
      getIndicators.setData();
    }

    if (createPostulation.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "La postulación se ha realizado correctamente",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setColumnEditPostulation();
      activeSection("description");
      refreshListColumns();

      createPostulation.setData();
    }
    if (createManyPostulation.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Las postulaciones se han realizado correctamente",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setColumnEditPostulation();
      activeSection("description");
      refreshListColumns();

      createManyPostulation.setData();
    }
    if (suggestionsIndicator.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Las sugerencias se han realizado correctamente",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      refreshListColumns();
      activeSection("description");
      suggestionsIndicator.setData();
    }
    if (deletePostulation.data) {
      if (!columnEditPostulation) {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "Las postulaciones se han eliminado correctamente",
        });
      }
      setLoading((prev) => ({ ...prev, description: false }));
      // if (!clickedDropdown) {
      //   activeSection("description");
      //   getInfoDoc();
      // }
      activeSection("description");
      refreshListColumns();
      deletePostulation.setData();
    }
    if (downloadDocument.data) {
      downloadFile(downloadDocument.data, infoDoc.title, ".csv");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });

      downloadDocument.setData();
    }
    if (downloadPdf.data) {
      downloadFile(downloadPdf.data, infoDoc.title, ".pdf");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });

      downloadPdf.setData();
    }
    if (isFavoriteDocument.data) {
      if (isFavoriteDocument.data.data.isFavorite) {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue agregado a favoritos.",
        });
        setIsFavorite(true);
      } else {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue eliminado de favoritos.",
        });
        setIsFavorite(false);
      }
      isFavoriteDocument.setData();
    }
    if (updateManyColumns.data) {
      setMessage({
        type: "success",
        title: "Resultado",
        description: "Las columnas fueron actualizadas correctamente.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      closeModal();
      activeSection("description");
      refreshListColumns();
      updateManyColumns.setData();
    }
  }, [
    getDataDoc.data,
    getDataFinal.data,
    getDescriptionDoc.data,
    getParentDoc.data,
    updateDescriptionColum.data,
    getMunicipalityForRegion.data,
    updateDescriptionDoc.data,
    getIndicators.data,
    createPostulation.data,
    downloadDocument.data,
    downloadPdf.data,
    isFavoriteDocument.data,
    deletePostulation.data,
    updateManyColumns.data,
    createManyPostulation.data,
    suggestionsIndicator.data,
  ]);
  // HANDLE ERRORS
  useEffect(() => {
    if (updateDescriptionColum.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setLoading((prev) => ({ ...prev, table: false }));
      updateDescriptionColum.setData();
    }

    if (updateManyColumns.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar las columnas. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setLoading((prev) => ({ ...prev, table: false }));
      updateManyColumns.setData();
    }

    if (updateDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setLoading((prev) => ({ ...prev, table: false }));
      updateDescriptionDoc.setData();
    }
    if (downloadDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
    }
    if (downloadPdf.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
    }
    if (isFavoriteDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, table: false }));
      isFavoriteDocument.setData();
    }
    if (createPostulation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar realizar la postulación. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));

      setLoading((prev) => ({ ...prev, table: false }));
      activeSection("description");
      createPostulation.setData();
    }
    if (createManyPostulation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar realizar las postulaciones. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      // if (!clickedDropdown) {
      //   activeSection("description");
      // }
      setLoading((prev) => ({ ...prev, table: false }));
      activeSection("description");
      createManyPostulation.setData();
    }

    if (suggestionsIndicator.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar realizar las sugerencias. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      setLoading((prev) => ({ ...prev, table: false }));
      activeSection("description");
      suggestionsIndicator.setData();
    }

    if (deletePostulation.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar eliminar la postulación. Revise los datos e inténtelo de nuevo.",
      });
      setLoading((prev) => ({ ...prev, description: false }));
      // if (!clickedDropdown) {
      //   activeSection("description");
      // }
      setLoading((prev) => ({ ...prev, table: false }));
      activeSection("description");
      deletePostulation.setData();
    }
    if (getDataDoc.error) {
      /* setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos de este archivo. Por favor inténtelo de nuevo mas tarde.",
      }); */
      setLoading((prev) => ({ ...prev, table: false }));
    }
    if (getDataFinal.error) {
      /* setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar obtener los datos de este archivo. Por favor inténtelo de nuevo mas tarde.",
      }); */
      setLoading((prev) => ({ ...prev, table: false }));
    }
  }, [
    updateDescriptionColum.error,
    updateDescriptionDoc.error,
    downloadDocument.error,
    downloadPdf.error,
    isFavoriteDocument.error,
    createPostulation.error,
    deletePostulation.error,
    getDataDoc.error,
    getDataFinal.error,
    updateManyColumns.error,
    createManyPostulation.error,
    suggestionsIndicator.error,
  ]);

  const handlePageChange = (page) => {
    setMetaData((prev) => ({ ...prev, page }));
    reloadData();
  };

  const handlePageFinalChange = (page) => {
    setMetaDataFinal((prev) => ({ ...prev, page }));
    reloadData();
  };

  const optionsFilter = [
    {
      id: 1,
      value: "La celda está vacía",
      label: "La celda está vacía",
    },
    {
      id: 2,
      value: "La celda no está vacía",
      label: "La celda no está vacía",
    },
    {
      id: 3,
      value: "El texto contiene",
      label: "El texto contiene",
    },
    {
      id: 4,
      value: "El texto no contiene",
      label: "El texto no contiene",
    },
    {
      id: 5,
      value: "El texto comienza por",
      label: "El texto comienza por",
    },
    {
      id: 6,
      value: "El texto termina en",
      label: "El texto termina en",
    },
    {
      id: 7,
      value: "El texto es exactamente",
      label: "El texto es exactamente",
    },
    {
      id: 8,
      value: "La fecha es",
      label: "La fecha es",
    },
    {
      id: 9,
      value: "La fecha es anterior",
      label: "La fecha es anterior",
    },
    {
      id: 10,
      value: "La fecha es posterior",
      label: "La fecha es posterior",
    },
    {
      id: 11,
      value: "Mayor que",
      label: "Mayor que",
    },
    {
      id: 12,
      value: "Mayor o igual que",
      label: "Mayor o igual que",
    },
    {
      id: 13,
      value: "Menor que",
      label: "Menor que",
    },
    {
      id: 14,
      value: "Menor o igual que",
      label: "Menor o igual que",
    },
    {
      id: 15,
      value: "Es igual a",
      label: "Es igual a",
    },
    {
      id: 16,
      value: "No es igual a",
      label: "No es igual a",
    },
    {
      id: 17,
      value: "Está entre",
      label: "Está entre",
    },
    {
      id: 18,
      value: "No está entre",
      label: "No está entre",
    },
  ];

  const handleDelete = () => {
    if (activeButton === "filter") {
      // eslint-disable-next-line no-console
      console.log("delete active filter");
    } else {
      // eslint-disable-next-line no-console
      console.log("delete description");
    }
  };

  const handleChangeTab = (keyTab) => {
    setListColumns(dataDoc.columns);
    setData(dataDoc.data);
    activeSection(keyTab);
  };

  const onHandleEdit = () => {
    setShowModal(true);
  };

  const handleReload = () => {
    setLoading((prev) => ({ ...prev, reloadTable: true }));
    if (activeButton === "description") {
      setLoading((prev) => ({ ...prev, table: true }));
      refreshListColumns();
    } else {
      getInfoDoc();
    }
    // Simular una carga de 2 segundos
    setTimeout(() => {
      setLoading((prev) => ({ ...prev, reloadTable: false }));
    }, 3000);
  };
  const onChangeSheet = (value) => {
    setLoading((prev) => ({ ...prev, infoDoc: true }));
    const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", value);
    navigate(path, { replace: true });
    activeSection("information");
    setChangeHeader(true);
    
    // window.location.assign(path);
  };

  const getRowKeys = (idsToFind) => {
    const statuses = data
      .filter((item) => idsToFind.includes(item.name))
      .map((item) => item.isSensitiveData);
    const allActive = statuses.every((status) => status);
    const allInactive = statuses.every((status) => !status);
    if (allActive) {
      setModeSelected("allActive");
      setSuggestSelected("allActive");
    } else if (allInactive) {
      setModeSelected("allInactive");
      setSuggestSelected("allInactive");
    } else {
      setModeSelected("none");
      setSuggestSelected("none");
    }
    setSelectedRowKeys(idsToFind);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (values) => getRowKeys(values),
  };

  const renderSection = () => {
    if (activeButton === "information" && !isNoFile) {
      return !loading.infoDoc ? (
        <DataPreviewInformation
          data={infoDoc}
          onHandleEdit={onHandleEdit}
          onHandleDownload={onDownloadDocument}
          handlerFavorite={onFavoriteDocument}
          isFavorite={isFavorite}
        />
      ) : (
        <div className="flex w-full justify-center">
          <Spin />
        </div>
      );
    }
    if (activeButton === "filter") {
      return (
        <DataPreviewFilter
          listActiveFilter={listActiveFilter}
          optionsFilter={optionsFilter}
          originColumn=""
          handleDelete={() => setOpenModalDelete(true)}
          listColumns={listColumns}
          actionFilterView={actionFilterView}
          setActionFilterView={() => setActionFilterView("add")}
        />
      );
    }
    if (activeButton === "profiling") {
      return <DataProfiling />;
    }
    if (activeButton === "share") {
      return <ShareFileTemplate infoFile={dataModalDescription} isWebSource={isNoFile} />;
    }
    return null;
  };

  const renderContentView = () => {
    if (!loading.table && !loading.infoDoc) {
      if (webSourceActive) {
        return (
          <IframeView
            urlView={urlView}
            className="!max-w-[calc(100vw-40px)] md:!max-w-[calc(100vw-200px)]"
          />
        );
      }
      if (pdfSourceActive) {
        return <PdfViewer pdfUrl={urlView} />;
      }
      return (
        <div className="w-full">
          <div className="w-full flex justify-end pb-4">
            <Button
              className="bg-lightBlue2 shadow-xl text-white_custom hover:text-lightBlue hover:shadow-none hover:bg-white_custom hover:border-lightBlue2"
              icon={<ReloadOutlined spin={loading.reloadTable} />}
              onClick={handleReload}
            >
              Actualizar tabla
            </Button>
          </div>
          <TableJoined
            dataInit={data}
            dataFinal={dataFinal}
            total={metadata.total}
            currentPageInit={metadata.page}
            currentPageFinal={metadataFinal.page}
            handlePageChangeInit={handlePageChange}
            handlePageChangeFinal={handlePageFinalChange}
            columns={listColumns}
            columnsFinal={listColumnsFinal}
            oneTable={activeButton === "description"}
            onlyView
            rowKey={activeButton !== "description" ? "UUID" : "name"}
            rowSelection={
              activeButton === "description" ? rowSelection : () => {}
            }
          />
          {/*      <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={metadata.total}
            currentPage={metadata.page}
            columns={listColumns}
            rowKey={activeButton !== "description" ? "UUID" : "name"}
            className="!max-w-[calc(100vw-40px)] md:!max-w-[calc(100vw-200px)] "
            onlyView
            breakWords={activeButton === "description"}
            hasPagination={activeButton === "information"}
          /> */}
        </div>
      );
    }

    return (
      <div className=" flex justify-center items-center">
        <Spin />
      </div>
    );
  };

  const renderContentModal = () => {
    if (activeButton === "information" && !clickedDropdown) {
      return (
        <SheetFormTemplate
          listColumns={columnsSelect}
          listMunicipalities={listMunicipalities}
          listCodification={listCodification}
          dataDescription={dataModalDescription}
          handlerSubmit={handlerSubmitDescription}
          loading={loading.description}
          listSheet={listSheet}
          isMultiSheet={dataModalDescription?.isChild}
          onHandleResetConfig={onHandleResetConfig}
        />
      );
    }
    if (activeButton === "description" && modalIndicator) {
      return (
        <AssociateIndicatorForm
          listGroupIndicator={listGroupIndicator}
          columnName={columnNameSelected}
          fileId={params?.key}
          onHandleSubmit={handleAssociatedIndicator}
          closeModal={closeModal}
          initialValues={columnEditPostulation}
        />
      );
    }
    return (
      <ColumnTemplate
        onHandleSubmit={onHandleSaveDesc}
        dataDesColumn={dataDesColumn}
        listTypeData={listDataTypes}
        closeModal={closeModal}
        loading={loading.description}
        multipleEdit={multipleEdit}
      />
    );
  };

  const renderButtonMode = () => {
    if (modeSelected === "allActive") {
      return (
        <>
          <CloseOutlined className="mr-1" />
          <p>Sensible</p>
        </>
      );
    }
    return (
      <>
        <CheckOutlined className="mr-1" />
        <p>Sensible</p>
      </>
    );
  };

  const onClickMultipleSensible = () => {
    const auxValues = {
      columns: selectedRowKeys,
      actionType: "Sensitive",
    };
    if (modeSelected === "allActive") {
      Object.assign(auxValues, { action: { isSensitiveData: false } });
    } else {
      Object.assign(auxValues, { action: { isSensitiveData: true } });
      // auxValues = { ...auxValues, isSensitiveData: true };
    }
    updateManyColumns.request(params?.key, auxValues);
  };

  const renderActionMode = () => {
    if (modeSelected === "none") {
      return null;
    }
    return (
      <div
        className={`${modeSelected === "allActive" ? "bg-dark_gray" : "bg-aquamarineBlue"} text-white hover:shadow-xl shadow-none w-[110px] !px-2 h-6 rounded`}
        onClick={onClickMultipleSensible}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="flex items-center justify-center h-full">
          {renderButtonMode()}
        </div>
      </div>
    );
  };

  const onClickSuggestion = (newState) => {
    // Lógica para aprobar sugerencias
    const auxData = {
      columns: [...selectedRowKeys],
      fileId: params?.key,
      state: newState,
    };
    suggestionsIndicator.request(auxData);
    setLoading((prev) => ({ ...prev, table: true }));
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => onClickSuggestion(ProposalStatus.PROPOSED)}
      >
        <div className="flex items-center">
          <CheckOutlined className="mr-1" />
          Aceptar
        </div>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onClickSuggestion(ProposalStatus.REJECTED)}
      >
        <div className="flex items-center">
          <CloseOutlined className="mr-1" />
          Rechazar
        </div>
      </Menu.Item>
    </Menu>
  );

  const renderSuggestButton = () => {
    if (suggestSelected === "none") {
      return null;
    }
    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <div
          className="bg-aquamarineBlue text-white hover:shadow-xl shadow-none w-[110px] !px-2 h-6 rounded cursor-pointer"
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center justify-center h-full">
            <p>Sugerencias</p>
            <DownOutlined className="ml-1" />
          </div>
        </div>
      </Dropdown>
    );
  };

  const onClickMultipleEdit = () => {
    setModalIndicator(false);
    setShowModal(true);
    setMultipleEdit(true);
  };

  return (
    <div className="flex flex-col items-center p-10 bg-white_custom">
      <div className="self-start text-base space-x-5 mb-5">
        {dataModalDescription && dataModalDescription.isChild ? (
          <SelectSheet
            label="Seleccione una hoja del documento"
            options={listSheet}
            defaultValue={dataModalDescription.id || dataModalDescription._id}
            onChange={onChangeSheet}
          />
        ) : null}

        <button
          onClick={() => handleChangeTab("information")}
          className={
            activeButton === "information" ? "button-underline" : undefined
          }
          type="button"
        >
          Datos
        </button>
        <button
          onClick={() => handleChangeTab("filter")}
          className={activeButton === "filter" ? "button-underline" : undefined}
          type="button"
          disabled
        >
          <Text disabled>Filtros </Text>
        </button>
        {!isNoFile ? (
          <button
            onClick={() => handleChangeTab("profiling")}
            className={
              activeButton === "profiling" ? "button-underline" : undefined
            }
            type="button"
          >
            Perfil de datos
          </button>
        ) : null}
        <ValidatePermissions
          func="oneButton"
          container={false}
          permission="create"
          modulePermission="document_management"
        >
          <button
            onClick={() => handleDescriptionClicked()}
            className={
              activeButton === "description" ? "button-underline" : undefined
            }
            type="button"
            disabled={isNoFile ? true : undefined}
          >
            {isNoFile ? <Text disabled> Columnas </Text> : "Columnas"}
          </button>
        </ValidatePermissions>

        <ValidatePermissions
          func="oneButton"
          container={false}
          permission="share"
          modulePermission="document_management"
        >
          <button
            onClick={() => handleChangeTab("share")}
            className={
              activeButton === "share" ? "button-underline" : undefined
            }
            type="button"
          >
            Compartir
          </button>
        </ValidatePermissions>
      </div>
      {activeButton !== "description" ? (
        <div className="mb-10 border-b border-[#bdb9b9]  w-full   py-5">
          {renderSection()}
        </div>
      ) : null}
      {activeButton !== "profiling" && activeButton !== "share" ? (
        <div className="w-full">{renderContentView()}</div>
      ) : null}

      <ModalConfirm
        title="Eliminar"
        text={`¿Está seguro de eliminar ${activeButton === "filter" ? "el filtro" : "la descripción"}?`}
        modalActive={openModalDelete}
        closeModal={() => setOpenModalDelete(false)}
        handleOK={handleDelete}
        isModalDelete
      />
      {RenderModalDeletePostulations()}
      {showModal ? (
        <AppModal
          open={showModal}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 font-bold  text-center !important"
          width={700}
        >
          {renderContentModal()}
        </AppModal>
      ) : null}
      {selectedRowKeys.length > 0 && activeButton === "description" ? (
        <FloatButton
          shape="square"
          className="min-w-fit px-5 py-2 bg-white cursor-default left-1/2 -translate-x-1/2"
          style={{ insetInlineEnd: 24 }}
          description={
            <div className="flex flex-wrap md:flex-nowrap items-center md:space-x-2">
              <p>{selectedRowKeys.length} Elementos seleccionados</p>
              <div
                className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
                onClick={() => {
                  setColumnNameSelected();
                  setColumnPostulation();
                  setColumnEditPostulation();
                  setMultipleEdit(true);
                  setModalIndicator(true);
                  setShowModal(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <div className="flex items-center justify-center h-full">
                  <PlusOutlined className="mr-1" />
                  Asociar
                </div>
              </div>
              <div
                className="bg-lightBlue2 text-white hover:shadow-xl shadow-none w-[110px] h-6 rounded"
                onClick={onClickMultipleEdit}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault();
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <div className="flex items-center justify-center h-full">
                  <EditOutlined className="mr-1" />
                  Tipo
                </div>
              </div>
              {renderActionMode()}
              {renderSuggestButton()}
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export default DataPreviewTemplate;
