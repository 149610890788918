/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spin, Form } from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Tour from "reactour";
import { useNavigate, useParams } from "react-router-dom";
import FormAutoSearch from "../../molecules/FormAutoSearch/FormAutoSearch";
import ComparativeAnalysisList from "../../organisms/ComparativeAnlysisForm/ComparativeAnalysisList";
import CardChart from "../../molecules/CardChart/CardChart";
import AppButton from "../../atoms/Buttons/AppButton";
import DisplayCards from "../../atoms/DisplayCards/DisplayCards";
import analysisRequest from "../../../api/analysisRequest";
import useApi from "../../../api/useApi";
import ComparativeAnalysisForm from "../../organisms/ComparativeAnlysisForm/ComparativeAnalysisForm";
import { useAuth } from "../../../contexts/AuthContext";
import ROUTES from "../../../constant/router";
import { useData } from "../../../contexts/DataContext";
import ensureJson from "../../../helpers/ensureJson";

const ComparativeAnalysisTemplate = ({
  dataCharts,
  handleEdit,
  handleDelete,
  handleButtonAdd,
  onDeleteChart,
  setDataCharts,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [data, setData] = useState([]);
  const getAllAnalysis = useApi(analysisRequest.getAllAnalysis);
  const getAnalysisById = useApi(analysisRequest.getAnalysisById);
  const createAnalysis = useApi(analysisRequest.createAnalysis);
  const updateAnalysis = useApi(analysisRequest.updateAnalysis);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState({
    list: false,
    Search: false,
    analysis: false,
  });
  const [form] = Form.useForm();
  const { setMessage } = useAuth();
  const [dataInfo, setDataInfo] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [switchClick, setSwitchClick] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { setChartToSnapshot, setUrlToUploadSnapshot } = useData();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: [] }],
        [{ font: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],

        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    },
    placeholder: "Click para añadir descripción...",
  });

  useEffect(() => {
    setLoading({ ...loading, list: true });
    getAllAnalysis.request();
    return () => setChartToSnapshot();
  }, []);

  const reloadData = () => {
    setLoading({ ...loading, list: true });
    getAllAnalysis.request();
  };

  useEffect(() => {
    if (params.id && Array.isArray(data)) {
      const auxList = [...data];
      if (auxList.length > 0) {
        const auxFlat = auxList.flatMap((item) => item.items);
        const auxFind = auxFlat.filter((item) => item.key === params.id);
        if (auxFind.length > 0) {
          const itemFound = auxFind[0];
          setDataInfo(itemFound);
          getAnalysisById.request(params.id);
          const auxJson = ensureJson(itemFound.analysis?.description);
          quill.setContents(auxJson);
          form.setFieldsValue({ name: itemFound.analysis?.name });
          setIsEdit(true);
        }
      }
    }
  }, [params.id, data, switchClick]);

  useEffect(() => {
    if (getAllAnalysis.data) {
      setData(getAllAnalysis.data.data?.items);
      setLoading({ ...loading, list: false, Search: false });
      getAllAnalysis.setData();
    }

    if (getAnalysisById.data) {
      setLoading((prev) => ({ ...prev, analysis: false }));

      const auxData = getAnalysisById.data.data[0];
      const charts = auxData.graphs.map((item) => {
        let auxDataChart = item.data;
        if (item.type === "stackedBar" || item.type === "scatter")
          auxDataChart = item.data?.arrayStacked;
        return { ...item, data: auxDataChart };
      });

      setDataCharts(charts);
      getAnalysisById.setData();
    }

    if (createAnalysis.data) {
      const auxData = Array.isArray(createAnalysis.data.data)
        ? createAnalysis.data.data[0]
        : {};
      const urlPre = auxData.url;
      setUrlToUploadSnapshot(urlPre);
      const auxId = auxData.Analysis?._id;
      const path = ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL.replace(
        ":id",
        auxId
      );
      navigate(path);
      reloadData();
      setMessage({
        type: "success",
        title: "Análisis creado",
        description: "El análisis se ha creado correctamente",
      });
      createAnalysis.setData();
    }
    if (updateAnalysis.data) {
      const urlPre = updateAnalysis.data.data?.url;
      setUrlToUploadSnapshot(urlPre);
      reloadData();
      setMessage({
        type: "success",
        title: "Análisis actualizado",
        description: "El análisis se ha actualizado correctamente",
      });
      updateAnalysis.setData();
    }
  }, [
    getAllAnalysis.data,
    getAnalysisById.data,
    createAnalysis.data,
    updateAnalysis.data,
  ]);

  useEffect(() => {
    if (createAnalysis.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al crear el análisis. Vuelva a intentarlo más tarde.",
      });
      createAnalysis.setData();
    }
    if (updateAnalysis.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar actualizar el análisis. Vuelva a intentarlo más tarde.",
      });
      updateAnalysis.setData();
    }

    if (getAnalysisById.error) {
      setLoading((prev) => ({ ...prev, analysis: false }));
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar cargar el análisis. Vuelva a intentarlo más tarde.",
      });
      getAnalysisById.setData();
    }
    if (getAllAnalysis.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar la lista de análisis. Vuelva a intentarlo más tarde.",
      });
      setLoading((prev) => ({ ...prev, list: false }));
    }
  }, [
    createAnalysis.error,
    updateAnalysis.error,
    getAnalysisById.error,
    getAllAnalysis.error,
  ]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const onSelect = (item) => {
    setSwitchClick((prev) => !prev);
    const path = ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL.replace(
      ":id",
      item.key
    );
    navigate(path);
    setLoading((prev) => ({ ...prev, analysis: true }));
  };

  const onSearch = (value) => {
    if (value !== "") {
      setLoading({ ...loading, Search: true });
      getAllAnalysis.request(value);
      setIsSearch(true);
    }
  };
  const onReset = () => {
    setLoading((prev) => ({ ...prev, list: true, Search: false }));
    getAllAnalysis.request();
    setIsSearch(false);
  };
  const OnNewAnalysis = () => {
    setIsEdit(false);
    form.resetFields();
    setDataCharts([]);
    const path = ROUTES.GENERAL.COMPARATIVE_ANALYSIS_DETAIL.replace("/:id", "");
    navigate(path);
    quill.setContents([{ insert: "\n" }]);
  };

  const onSubmit = () => {
    form.submit();
    if (dataCharts.length !== 0 && form.getFieldValue() !== undefined) {
      const firstChart = dataCharts[0]?.type;
      const aux = form.getFieldValue();
      if (aux.name) {
        setChartToSnapshot(firstChart);
        aux.description = JSON.stringify(quill.getContents());
        const dataChartsAux = dataCharts.map((item) => {
          let auxDataChart = item.data;
          if (item.type === "stackedBar" || item.type === "scatter")
            auxDataChart = { arrayStacked: auxDataChart };
          const auxItem = { ...item, data: auxDataChart };
          delete auxItem.id;
          return auxItem;
        });
        aux.graphs = dataChartsAux;
        if (isEdit) {
          updateAnalysis.request(dataInfo?.key, aux);
        } else {
          createAnalysis.request(aux);
          setIsEdit(true);
        }
      } else {
        setMessage({
          type: "error",
          title: "Datos vacíos",
          description: "Es necesario poner un título",
        });
      }
    } else {
      setMessage({
        type: "error",
        title: "Datos vacíos",
        description:
          "Por favor llene todos los campos y agregue al menos una gráfica",
      });
    }
  };

  // Configuración del tour
  const [isTourOpen, setIsTourOpen] = useState(false);
  const tourSteps = [
    {
      selector: ".step-sidebar",
      content:
        "Aquí puedes buscar y seleccionar un análisis comparativo existente.",
    },
    {
      selector: ".step-new-analysis",
      content: "Haz clic aquí para crear un nuevo análisis comparativo.",
    },
    {
      selector: ".step-form",
      content: "Completa el formulario con la información del análisis.",
      action: (node) => {
        const interval = setInterval(() => {
          if (node.style.display !== "none") {
            clearInterval(interval);
          }
        }, 100);
      },
    },
    {
      selector: ".step-add-chart",
      content: "Agrega una nueva gráfica para tu análisis.",
      action: (node) => {
        const interval = setInterval(() => {
          if (node.childElementCount > 0) {
            clearInterval(interval);
          }
        }, 100);
      },
    },
    {
      selector: ".step-chart-list",
      content:
        "Aquí puedes ver y gestionar las gráficas añadidas a tu análisis.",
    },
    {
      selector: ".step-submit",
      content: "Finalmente, guarda o actualiza tu análisis aquí.",
    },
  ];

  return (
    <Row className="">
      {isSidebarVisible && (
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={6}
          className="mb-2 z-50 step-sidebar"
        >
          <div className="bg-white_custom border border-colorBorder/[.29] flex flex-col items-center mx-4  rounded-[16px] shadow-2xl mt-8 min-w-min max-w-[429px] h-full">
            <div className="w-full flex items-end justify-end pb-4">
              <Button
                icon={
                  isSidebarVisible ? (
                    <ArrowLeftOutlined />
                  ) : (
                    <ArrowRightOutlined />
                  )
                }
                onClick={toggleSidebar}
                className="m-4 bg-backGroundBanner text-white_custom"
              />
            </div>
            <div className="px-4 w-5/6">
              <div className="w-full">
                <div className="pb-4 mb-4">
                  <FormAutoSearch
                    submitSearch={onSearch}
                    resetValue={!isSearch}
                    styleForm="pb-0 mb-0"
                    loading={loading.Search}
                  />
                  {isSearch && (
                    <div className="flex flex-col justify-end items-end">
                      <Button
                        shape="round"
                        type="text"
                        className="font-Poppins text-lightBlue2 hover:text-lightBlue2 hover:font-medium items-end"
                        onClick={onReset}
                      >
                        {" "}
                        Restablecer{" "}
                      </Button>
                    </div>
                  )}
                </div>
                <Button
                  shape="round"
                  type="primary"
                  className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom w-full hover:bg-lightBlue2 hover:font-medium step-new-analysis"
                  onClick={OnNewAnalysis}
                >
                  <span>
                    Nuevo análisis comparativo
                    <PlusOutlined
                      style={{ fontSize: "14px" }}
                      className="pl-2"
                    />
                  </span>
                </Button>
              </div>
              {loading.list ? (
                <div className="flex justify-center items-center py-4">
                  <Spin />
                </div>
              ) : (
                <div className="w-full py-4">
                  <ComparativeAnalysisList
                    dataList={data}
                    onSelectItem={onSelect}
                    isSelected={isEdit}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      )}
      <Col
        xs={24}
        sm={24}
        md={isSidebarVisible ? 18 : 24}
        lg={isSidebarVisible ? 18 : 24}
        xl={isSidebarVisible ? 18 : 24}
        className="mb-2"
      >
        {!isSidebarVisible && (
          <Button
            icon={
              isSidebarVisible ? <ArrowLeftOutlined /> : <ArrowRightOutlined />
            }
            onClick={toggleSidebar}
            className="my-4 mx-2 bg-backGroundBanner text-white_custom"
          />
        )}
        <div className="bg-white_custom  border border-colorBorder/[.29] rounded-[16px] shadow-2xl w-full h-full flex flex-col mt-8 items-center pt-10">
          <div className="flex w-full mx-10 mb-2 px-4 step-form">
            <ComparativeAnalysisForm
              form={form}
              isEdit={isEdit}
              quill={quill}
              quillRef={quillRef}
              onSubmit={onSubmit}
            />
          </div>
          {!loading.analysis ? (
            <>
              <div className="flex w-full mx-10 mb-2 px-4 step-add-chart">
                <DisplayCards
                  list={dataCharts}
                  onDeleteCard={onDeleteChart}
                  title="Gráficas creadas:"
                  messageEmpty="No Hay gráficas creadas por el momento"
                />
                <div className="flex justify-end w-1/4">
                  <AppButton
                    onClick={handleButtonAdd}
                    title={
                      <div>
                        Nueva Gráfica <PlusOutlined />
                      </div>
                    }
                    className="!bg-lightGreen text-white  max-h-[120px] self-center"
                  />
                </div>
              </div>
              <div className="flex w-full flex-wrap max-h-min justify-center ml-[-8px] step-chart-list">
                {Array.isArray(dataCharts) &&
                  dataCharts.map((item, i) => (
                    <CardChart
                      typeChart={item.type}
                      title={item.name}
                      data={item.data}
                      titleX={item.columnName?.xAxis}
                      titleY={item.columnName?.yAxis}
                      handleEdit={() => handleEdit(i)}
                      handleDelete={() => handleDelete(i)}
                    />
                  ))}
              </div>
            </>
          ) : (
            <div className="flex items-center h-full mb-8">
              <Spin />
            </div>
          )}
          {/* Botón para iniciar el tour */}
          <div className="start-tour-button">
            <Button onClick={() => setIsTourOpen(true)}>
              Iniciar instructivo
            </Button>
          </div>
        </div>
      </Col>

      {/* Componente Tour */}
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        accentColor="#5cb7b7"
        showSkipButton
        nextButton={<Button>Siguiente</Button>}
        prevButton={<Button>Anterior</Button>}
        skipButton={<Button>Saltar tutorial</Button>}
      />
    </Row>
  );
};

export default ComparativeAnalysisTemplate;
