import React, { useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-dist-min";
import useImageChart from "../../../hooks/useImageChart";

const PieChart = ({ values, labels, title, titleX }) => {
  const chartRef = useRef(null);
  const plotRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        window.requestAnimationFrame(() => {
          Plotly.Plots.resize(chartRef.current);
        });
      }
    };

    // Set initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useImageChart({
    plotRef,
    chartType: "pie",
    imageConfig: { height: 500, width: 500 },
  });

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}>
      <Plot
        ref={plotRef}
        data={[
          {
            type: "pie",
            values,
            labels,
            textinfo: "label+percent",
            textposition: "inside",
            automargin: true,
            hoverinfo: "label+percent+name",
            name: titleX,
          },
        ]}
        layout={{
          title,
          showlegend: false,
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onInitialized={(_, graphDiv) => {
          plotRef.current = graphDiv;
        }}
      />
    </div>
  );
};

export default PieChart;
