import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";

const AppBreadcrumb = ({ path = [] }) => {
  const returnItemsPath = () => {
    let auxPath = [
      {
        href: "/dashboard",
        title: <HomeOutlined />,
      },
    ];
    if (Array.isArray(path) && path.length > 0 && path[0].title) {
      const auxArr = path.map((item) => {
        if (item.href) return { title: item.title, href: item.href };
        return { title: item.title };
      });
      auxPath = auxPath.concat(auxArr);
    }
    return auxPath;
  };
  return <Breadcrumb items={returnItemsPath()} />;
};

export default AppBreadcrumb;
