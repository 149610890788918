/* eslint-disable indent */
import { CalendarOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Card from "../../atoms/Card/Card";
import AppButton from "../../atoms/Buttons/AppButton";
import { localeTime } from "../../../helpers/transformUTCtoColombiaTime";
// import chartTemp from "../../../assets/image/dashboardTemp/chartIndicator.png";

const { Text } = Typography;

const HomeLastAnalysis = ({ className, listLastAnalysis = [], handleCardClick }) => (
  <div className={className}>
    <strong className="ml-5">Análisis comparativos</strong>
    <div className="flex py-3 px-5 space-x-4 overflow-auto">
      {Array.isArray(listLastAnalysis) && listLastAnalysis.length > 0
        ? listLastAnalysis.map((item) => (
            <Card className="min-w-56 bg-white ">
              <div className="flex w-full justify-center bg-white">
                <img src={item.urlImage} alt="" className="w-40" />
              </div>
              <Text className="font-bold" ellipsis={{ tooltip: item.name }}>
                {item.name}
              </Text>
              <div className="flex mt-2">
                <div className="flex items-start h-full">
                  <CalendarOutlined className="text-lightBlue2 mt-1" />
                </div>
                <div className="ml-2">
                  <span className="font-medium">Ultima actualización:</span>
                  <p>{localeTime(item.createdAt)}</p>
                </div>
              </div>
              <div className="flex w-full justify-center mt-2">
                <AppButton className="bg-aquamarineBlue py-0" title="Ver" onClick={()=>handleCardClick(item)}/>
              </div>
            </Card>
          ))
        : "No has subido análisis"}
    </div>
  </div>
);

export default HomeLastAnalysis;
